import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.scss";
import HomePage from "./pages/HomePage/HomePage";
// import AcademyPage from "./pages/AcademyPage/AcademyPage";
import AskDemo from "./pages/AskDemo/AskDemo";
import BlogPage from "./pages/BlogPage/BlogPage";
import CompanyPage from "./pages/CompanyPage/CompanyPage";
// import FeaturesPage from "./pages/FeaturesPage/FeaturesPage";
import PricingPage from "./pages/PricingPage/PricingPage";
import ScrollToTop from "./pages/ScrollToTop";
// import StudentsPage from "./pages/StudentsPage/StudentsPage";
// import StaffPage from "./pages/StaffPage/StaffPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import TermsPage from "./pages/TermsPage/TermsPage";
import { withRouter } from "react-router-dom";
class App extends React.Component {
  componentDidUpdate(prevProps) {
    const { location: prevHistory } = prevProps;
    const { location: currHistory } = this.props;

    if (prevHistory.pathname !== currHistory.pathname) {
      const bodyEl = document.querySelector(".menu-active");
      bodyEl && bodyEl.classList.remove("menu-active");
    }
  }

  render() {
    return (
      <>
        <ScrollToTop />
        <Switch>
          <Route component={HomePage} path="/" exact />
          {/* <Route component={CompanyPage} path="/company" exact />
          <Route component={BlogPage} path="/blog" exact /> */}
          {/* <Route component={PricingPage} path="/pricing" exact /> */}
          {/* <Route component={FeaturesPage} path="/features/:module?" exact /> */}
          {/* <Route component={StudentsPage} path="/students" exact />
          <Route component={AcademyPage} path="/academy" exact />
          <Route component={StaffPage} path="/staff" exact /> */}
          <Route component={AskDemo} path="/ask-us-demo" exact />
          <Route component={TermsPage} path="/terms" exact />
          <Route component={PrivacyPage} path="/privacypolicy" exact />
        </Switch>
      </>
    );
  }
}
export default withRouter(App);
