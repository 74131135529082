import React, { useState } from "react";
import "./FAQ.styles.scss";

export default function FAQ() {
  const [activeItem, setActiveItem] = useState(0);
  // require("../../../AcademyPage/modules/common.css");
  const faqClickHandler = (item) => {
    if (item === activeItem) {
      setActiveItem(0);
      return;
    }
    setActiveItem(item);
    return;
  };
  return (
    <section class="faqsBlock space-14 wow fadeInUp">
      <div class="container">
        <div class="row">
          <h2 class="text-center">Fatcat FAQ</h2>
          <div class="faqsList">
            <div onClick={(e) => faqClickHandler(1)} class="item">
              <div class={activeItem === 1 ? "wc-title active" : "wc-title"}>
                <h3 class="large">
                How does Fatcat introduce technology on campus?
                </h3>
              </div>
              <div class="wc-text">
                <p>
                Fatcat provides digital and technical assistance to colleges and universities to introduce online learning, AI-backed systems for attendance records, transportation management, library management, digital payments, etc.{" "}
                </p>
              </div>
            </div>
            <div onClick={(e) => faqClickHandler(2)} class="item">
              <div class={activeItem === 2 ? "wc-title active" : "wc-title"}>
                <h3 class="large">
                What is the cost involved?
                </h3>
              </div>
              <div class="wc-text">
                <p>
                LFatcat has some free modules for the educational institutes. To know more about prices, reach out to us on sales@fatcat.net.in
                </p>
              </div>
            </div>
            <div onClick={(e) => faqClickHandler(3)} class="item">
              <div class={activeItem === 3 ? "wc-title active" : "wc-title"}>
                <h3 class="large">How do I subscribe for free demos?</h3>
              </div>
              <div class="wc-text">
                <p>
                To get free demos, simply fill the contact form and we will get back to you at the earliest.
                </p>
              </div>
            </div>
          </div>
          {/* <div class="cta-wrapper text-center">
            <a href="#" class="cta blue1 fill large">
              View All FAQs
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}
