import React from "react";
import "./Survey.styles.scss";
import surveryIcon from "../../../../assets/images/survey-cta-icon.svg";

export default function Survery() {
  return (
    <div className="survey-cta-wrapper wow fadeInUp">
      <div className="survey-cta">
        <div className="survey-cta-context">
          <span className="context-title">
          Participate in our 5 minute survey
          </span>
          <a target='_blank' href="https://fatcat-survey.typeform.com/to/BZJA0pgC"> <button className="context-btn">Take Survey</button></a>
        </div>
        <div className="survey-cta-image">
          <img src={surveryIcon} alt="survery-icon" />
        </div>
      </div>
    </div>
  );
}