import React from "react";
import "./Footer.styles.scss";
import Fatcat_Logo_ from "../../../../assets/images/Fatcat_Logo_.png";
import icon1 from "../../../../assets/images/so-1.png";
import icon2 from "../../../../assets/images/so-2.png";
import icon3 from "../../../../assets/images/so-3.png";
import icon4 from "../../../../assets/images/so-4.png";
import instagram from "../../../../assets/images/instagram.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="footer-container wow fadeInUp">
        <div className="footer-left">
          <img src={Fatcat_Logo_} alt="footer logo" />
          <div className="footer-left-social">
            <span className="footer-left-text">
              Fatcat Innovations Pvt. Ltd.
            </span>
            <span className="footer-left-seperator"></span>
            <span className="footer-left-social-icons">
              <a
                href="https://github.com/Team-Fatcat"
                target="_blank"
                title="Git"
                rel="noopener"
              >
                <img src={icon1} alt="social-icon1" />
              </a>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a
                href=" https://www.facebook.com/Fatcat.edu"
                target="_blank"
                title="facebook"
                rel="noopener"
              >
                <img src={icon2} alt="social-icon2" />
              </a>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a
                href=" https://twitter.com/Fatcat32162622"
                target="_blank"
                title="Twitter"
                rel="noopener"
              >
                <img src={icon3} alt="social-icon3" />
              </a>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a
                href="https://www.linkedin.com/company/fatcat-tech"
                target="_blank"
                title="Linkedin"
                rel="noopener"
              >
                <img src={icon4} alt="social-icon4" />
              </a>
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <a
                href="https://instagram.com/fatcat_official_?igshid=2p8tmvocp3e4"
                target="_blank"
                title="Linkedin"
                rel="noopener"
              >
                <img src={instagram} alt="social-icon4" />
              </a>
            </span>
          </div>
          <p className="footer-left-terms">
            © 2023 Fatcat, All rights reserved |<a href="/terms">Terms</a> &
            <a href="/privacypolicy"> Privacy</a>
          </p>
        </div>

        <div className="footer-right">
          {/* <span>
            <a href="/academy">Institutes</a>
          </span>
          <span>
            <a href="/students">Students</a>
          </span>
          <span>
            <a href="/staff">Educators</a>
          </span> */}

          {/* <span>
            <a href="/features">Features</a>
          </span> */}
          {/* <span>
            <a href="/company">Company</a>
          </span>
          <span>
            <a href="/blog">Blog</a>
          </span> */}
        </div>
      </div>
    </footer>
  );
}
