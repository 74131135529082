import axios from "axios";
export const handleEmailSubmission = async (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValid = re.test(String(email).toLowerCase());

  const payload = {
    email,
  };
  if (isEmailValid) {
    try {
      const { data } = await axios.post(
        "https://fatcat.net.in:1234/api/earlyaccess",
        payload
      );
      if (data) {
        alert("Thank you! Please check your mail");
        window.location.reload();
      }
    } catch (error) {
      alert("Try Again!");
    }
  } else {
    alert("Invalid Email. Please try again.");
  }
};

export const handleNeedSubmission = async (needs) => {
  const payload = {
    needs,
  };
  if (needs.length) {
    try {
      const { data } = await axios.post(
        "https://fatcat.net.in:1234/api/specificneeds",
        payload
      );
      if (data) {
        alert("Thank you! Please participate in the above survey to help us understand better");
        window.location.reload();
      }
    } catch (error) {
      alert("Try Again!");
    }
  } else {
    alert("Cannot submit empty fields. Try again.");
  }
};
