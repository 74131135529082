import React, { useEffect, useState } from "react";
import * as yup from "yup";
// import Navbar from "../CompanyPage/components/Navbar/Navbar";
import Footer from "../HomePage/components/Footer/Footer";
import Navbar from "../StudentsPage/components/Navbar/Navbar";
import of1 from "../../assets/images/location.png";
import of2 from "../../assets/images/mail.png";
import of3 from "../../assets/images/number.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import fb from "../../assets/images/facebook.png";
import Survey from "./components/Survery/Survey";
import FAQ from "./components/FAQ/FAQ";
import OtherFeatures from "../FeaturesPage/components/OtherFeatures/OtherFeatures";
import WOW from "wow.js";
// import Navbar
import "./AskDemo.styles.scss";
import axios from "axios";
export default function AskDemo() {
  new WOW().init();
  React.useEffect(() => {
    const linkEl = document.createElement("link");
    linkEl.rel = "stylesheet";
    linkEl.href = "css/modules/main.css";
    linkEl.id = "custom-css";
    document.head.appendChild(linkEl);
    return () => {
      const linkEl = document.head.querySelector("#custom-css");
      linkEl && document.head.removeChild(linkEl);
    };
  }, []);
  // require("../StudentsPage/modules/variables.css");
  // require("../AcademyPage/modules/responsive.css");
  const [windowDimension, setWindowDimension] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // form
  const [errName, setErrName] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errDesignation, setErrDesignation] = useState("");
  const [errSize, setErrSize] = useState("");
  const [errInstitute, setErrInstitute] = useState("");
  const [errDescription, setErrDescription] = useState("");
  const [apiResponse, setApiResponse] = useState({});
  // touched
  const [n, nt] = useState(false);
  const [p, pt] = useState(false);
  const [ee, et] = useState(false);
  const [d, dt] = useState(false);
  const [s, st] = useState(false);
  const [i, it] = useState(false);
  const [des, dest] = useState(false);

  const [reference, setReference] = useState("");
  const [token, setToken] = useState("");
  const [submitted, setSubmitted] = useState(false);

  let nameRef = React.useRef();
  let phoneRef = React.useRef();
  let emailRef = React.useRef();
  let designationRef = React.useRef();
  let sizeRef = React.useRef();
  let instituteRef = React.useRef();
  let descriptionRef = React.useRef();
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const nameOnkeyUp = (e) => {
    setErrName("");
    setErrPhone("");
    setErrEmail("");
    setErrDesignation("");
    setErrSize("");
    setErrInstitute("");
    setErrDescription("");
    let textName = e.target.name;
    if (textName === "name") {
      nt(true);
    }
    if (textName === "phone") {
      pt(true);
    }
    if (textName === "email") {
      et(true);
    }
    if (textName === "designation") {
      dt(true);
    }
    if (textName === "size") {
      st(true);
    }
    if (textName === "institute") {
      it(true);
    }
    if (textName === "description") {
      dest(true);
    }
    let formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      designation: designationRef.current.value,
      size: parseInt(sizeRef.current.value),
      institute: instituteRef.current.value,
      description: descriptionRef.current.value,
    };
    schema
      .validate(formData, { abortEarly: false })
      .then(function (e) {
        // console.log({e})
      })
      .catch(function (err) {
        err.inner.forEach((e) => {
          if (e.path === "name" && n === true) {
            setErrName(e.message);
          }
          if (e.path === "phone" && p === true) {
            setErrPhone(e.message);
          }
          if (e.path === "email" && ee === true) {
            setErrEmail(e.message);
          }
          if (e.path === "designation" && d === true) {
            setErrDesignation(e.message);
          }
          if (e.path === "size" && s === true) {
            setErrSize(e.message);
          }
          if (e.path === "institute" && i === true) {
            setErrInstitute(e.message);
          }
          if (e.path === "description" && des === true) {
            setErrDescription(e.message);
          }
          // console.log(e.message, e.path)
        });
      });
  };
  const isMobile = windowDimension <= 640;
  const schema = yup.object({
    name: yup
      .string()
      .min(3, "Too short. Minimum length is ${min}")
      .required("Name is required"),
    email: yup
      .string()
      .email("Enter valid email address")
      .required("Email is required"),
    phone: yup
      .number("Enter a valid phone number")
      .min(1000000000, "Enter a valid phone number")
      .max(9999999999, "Enter a valid phone number")
      .typeError("Enter a valid phone number")
      .required("Phone is  required"),
    designation: yup.string().required("Designation is required"),
    size: yup.string().required("size is  required"),
    institute: yup.string().required("Institution name is required"),
    // description: yup.string().required("Description is  required"),
  });
  const handleSubmit = async (e) => {
    let isFormValid = false;
    e.preventDefault();
    await setSubmitted(true);
    await setErrName("");
    await setErrPhone("");
    await setErrEmail("");
    await setErrDesignation("");
    await setErrSize("");
    await setErrInstitute("");
    // await setErrDescription("");
    nt(true);
    et(true);
    pt(true);
    dt(true);
    st(true);
    it(true);
    // dest(true);
    let data = {
      fullname: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      designation: designationRef.current.value,
      size: parseInt(sizeRef.current.value) || 0,
      institutionName: instituteRef.current.value,
      // description: descriptionRef.current.value,
    };
    let formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      designation: designationRef.current.value,
      size: parseInt(sizeRef.current.value),
      institute: instituteRef.current.value,
      // description: descriptionRef.current.value,
    };
    await schema
      .validate(formData, { abortEarly: false })
      .then(function (e) {
        isFormValid = true;
      })
      .catch(function (err) {
        err.inner.forEach((e) => {
          if (e.path === "name") {
            setErrName(e.message);
          }
          if (e.path === "phone") {
            setErrPhone(e.message);
          }
          if (e.path === "email") {
            setErrEmail(e.message);
          }
          if (e.path === "designation") {
            setErrDesignation(e.message);
          }
          if (e.path === "size") {
            setErrSize(e.message);
          }
          if (e.path === "institute") {
            setErrInstitute(e.message);
          }
          // if (e.path === "description") {
          //   setErrDescription(e.message);
          // }
        });
      });
    if (isFormValid === true) {
      axios
        .post("https://fatcat.net.in:1234/api/contact", data)
        .then(async (res) => {
          let response = res.data;
          console.log(response);
          await setReference(response.refereneceNo);
          await setToken(response.tokenNo);
          await setIsSubmitted(true);
          await setApiResponse(response);
        })
        .catch((error) => {
          alert("There was a problem in loading, please check your mail");
        });
      console.log(isSubmitted);
    }
  };

  return (
    <div id="ask-demo-page" className="fp-container">
      {/* <Navbar isMobile={isMobile} /> */}
      <Navbar affix={true} />
      <section>
        <div className="new-container">
          <h2>Sign up for your free trial</h2>
          <div className="shadowBox-coniner">
            <div className="w-50">
              {isSubmitted ? (
                <div className="succes-box">
                  <div className="top-box">
                    <div className="d-flex">
                      <h3>Congratulations!!</h3>
                      <p>Reference no. {reference}</p>
                    </div>
                    <div className="d-flex datebox p-0 align-center">
                      {/* <img src={date} alt="date" className="dateimg" /> */}
                      <div className="askDemo__id">
                        <div
                          style={{
                            width: "100%",
                            borderTop: "7px solid white",
                          }}
                        >
                          <h1 className="askDemo__id__h1">{apiResponse.id}</h1>
                        </div>
                      </div>
                      <div>
                        <h5>You Are On the List!</h5>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-box">
                    <a href="#">Registrar Details</a>
                    <h3 style={{ marginBottom: "0px" }}>
                      {apiResponse.fullname}
                    </h3>
                    <p>{apiResponse.institutionName}</p>
                    <div className="d-flex">
                      <div className="w-50">
                        <label>Contact Number</label>
                        <p>{apiResponse.phone}</p>
                      </div>
                      <div className="w-50">
                        <label>Contact Id</label>
                        <p>{apiResponse.email}</p>
                      </div>
                    </div>
                    <p className="bottom-text">
                      Fatcat team will contact you as soon as possible
                    </p>
                  </div>
                </div>
              ) : (
                <form className="shadowBox" onSubmit={handleSubmit} noValidate>
                  <div className="form-container">
                    <div className="group w-50">
                      <label>Full name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="John Doe"
                        name="name"
                        ref={nameRef}
                        onKeyUp={nameOnkeyUp}
                      ></input>
                      <p className="err-message">{errName}</p>
                    </div>
                    <div className="group w-50">
                      <label>E mail ID</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="johndoe@mymail.com"
                        ref={emailRef}
                        name="email"
                        onKeyUp={nameOnkeyUp}
                      ></input>
                      <p className="err-message">{errEmail}</p>
                    </div>
                  </div>
                  <div className="form-container">
                    <div className="group w-50">
                      <label>Phone number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="987 654 3210"
                        ref={phoneRef}
                        onKeyUp={nameOnkeyUp}
                      ></input>
                      <p className="err-message">{errPhone}</p>
                    </div>
                    <div className="group w-50">
                      <label>Designation</label>
                      <input
                        type="text"
                        className="form-control"
                        name="designation"
                        placeholder="Registrar"
                        ref={designationRef}
                        onKeyUp={nameOnkeyUp}
                      ></input>
                      <p className="err-message">{errDesignation}</p>
                    </div>
                  </div>
                  <div className="form-container">
                    <div className="group w-50">
                      <label>Size of your institue</label>
                      <select
                        ref={sizeRef}
                        name="size"
                        onKeyUp={nameOnkeyUp}
                        className="form-control"
                      >
                        <option value="1">500 - 2000</option>
                        <option value="2">2000 - 3000</option>
                        <option value="3">3000 - 5000</option>
                        <option value="4"> {">"}5000</option>
                      </select>
                      <p className="err-message">{errSize}</p>
                    </div>
                    <div className="group w-50">
                      <label>Institute Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Institute Name"
                        ref={instituteRef}
                        name="institute"
                        onKeyUp={nameOnkeyUp}
                      ></input>
                      <p className="err-message">{errInstitute}</p>
                    </div>
                  </div>
                  {/* <div className="form-container">
                    <div className="group w-100">
                      <label>
                        Please describe what you would like to achieve
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="description"
                        placeholder="I would like to discuss about....."
                        ref={descriptionRef}
                        onKeyUp={nameOnkeyUp}
                      ></textarea>
                      <p className="err-message">{errDescription}</p>
                    </div>
                  </div> */}
                  <button>Sign Up</button>
                </form>
              )}
            </div>
            <div className="">
              <div className="shadowBox-right">
                <div class="right-content">
                  <h3>Reach out to Us at:</h3>
                  <div className="d-flex">
                    <img src={of1} alt="location" />
                    <div className="details">
                      Ground Floor, Vindhya C4,<br></br>
                      IIIT-H Campus, Gachibowli, Telangana 500032
                    </div>
                  </div>
                  <div className="d-flex">
                    <img src={of2} alt="email" />
                    <div className="details">
                      <a href="mailto:sales@fatcat.net.in">
                        sales@fatcat.net.in
                      </a>
                    </div>
                  </div>
                  <div className="d-flex">
                    <img src={of3} alt="number" />
                    <div className="details">
                      <a href="tel:9704640910">+91 9704640910</a>
                    </div>
                  </div>
                </div>
                <h3>Find Us At:</h3>
                <div className="d-flex social">
                  <a
                    href=" https://twitter.com/Fatcat32162622"
                    target="_blank"
                    title="Twitter"
                    rel="noopener"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/fatcat-tech"
                    target="_blank"
                    title="Linkedin"
                    rel="noopener"
                  >
                    <img src={linkedin} alt="Linkedin" />
                  </a>
                  <a
                    href=" https://www.facebook.com/Fatcat.edu"
                    target="_blank"
                    title="facebook"
                    rel="noopener"
                  >
                    <img src={fb} alt="Facebook" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="bg-1">
        <OtherFeatures />
      </div> */}

      <FAQ />
      <Survey />
      <Footer />
    </div>
  );
}
