import React from "react";
import Navbar from "../StudentsPage/components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import WOW from 'wow.js'
import "./PrivacyPage.styles.scss";

function HomePage() {
  require("../../animate.css");
  new WOW().init();
  return (
    <div className="homepage-container">
      <Navbar />
      <div className="terms__wrapper px-5">
        <h1 className="terms__heading">Privacy Policy</h1>
        <p className="terms__description">Welcome to Fatcat.com operated by Trilot Solutions Pvt. Ltd. (“Fatcat” or “we” or “us” or “our'' or "Site"). This Privacy Policy sets forth Fatcat’s policy concerning personal data and other information that is collected from users of the Site. Your trust is priceless to us and protecting the information gathered is of priority and significance to us.</p>
        <p className="terms__description">The use of the Web, mobile device, and its goods and services is controlled by this Privacy Policy. All aspects of this Privacy Policy are in agreement with the Information Technology Act, 2000 (“IT Act”) and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (“IT Rules”).</p>
        <h1 className="terms__subHeading">Information We Collect:</h1>
        <p className="terms__description">We may collect Personal Data and other information from you when you use the Site, as further stated below:</p>
        <p className="terms__description"><b>Personal Data You Provide Through the Site: </b> We obtain Personal Data from you when you provide it voluntarily. If you provide Personal Data to us, you acknowledge and agree that such Personal Data may be transferred to the offices and servers of Fatcat and other authorized third-parties as per applicable law.</p>
        <p className="terms__description">Fatcat will, at all times, provide the option to you, not to provide the Personal Data, which it seeks from you. Further, you can, at any time while using the products, have an option to withdraw your consent given to us earlier to use such information, by sending us an email provided in this Policy below. In any such event, Fatcat fully reserves the right to withdraw further usage of the product.</p>
        <h1 className="terms__subHeading">Other Information:</h1>
        
        <p className="terms__description">Non-Identifiable Data: When you interact with Fatcat through the Site, we receive and store certain personally non-identifiable information that cannot be used to specifically identify you. Fatcat may store such information in databases owned and maintained by Fatcat affiliates, agents, or service providers. We may use such information and pool it with other information to track client behavior, and we may publish or otherwise disclose such aggregated information</p>
        <p className="terms__description">Aggregated Personal Data: Fatcat may research the characteristics of users, such as demographics, interests, and behavior based on the Personal Data and other information. This research may be compiled and analyzed on an aggregate basis and published. This aggregate information does not identify you personally.</p>
        <p className="terms__description">Analytics and Tracking Technologies: We may use cookies or similar technologies to collect information about your browsing activities over time and across different websites following your use of the Site. Please refer to the "help" section of your browser for information on how to receive notifications when you are receiving a new cookie and how to turn cookies off. We recommend you leave cookies turned on to take advantage of some of the Site’s features.</p>

        <h1 className="terms__subHeading">Our Use of Your Personal and Other Data:</h1>
        <p className="terms__description">If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided or for other Fatcat related purposes. For instance (but without limitation): </p>
        <p className="terms__description">
        1.	If you provide Personal Data to subscribe to Fatcat products, we may use it to provide you with access to the Site and to monitor your use of the Site. <br/>
        2.	If you subscribe to and deploy the product offered by Fatcat, the name of your institution may appear on our social media handles for promotion purposes.  <br/>
        3.	Fatcat may also use your data to help us manage and administer and improve the Site and to better understand our users. 
        </p>

        <h1 className="terms__subHeading">Our Disclosure of Your Data, Personal and otherwise</h1>
        <p className="terms__description">We may share your Personal Data with third parties as required below:</p>
        <p className="terms__description"> ●	When we employ or engage third parties to perform a function for our Site, we provide them with the information that they need to perform their specific function under appropriate confidentiality obligations.</p>
        <p className="terms__description">●	Fatcat may disclose your Personal Data when there is a legal requirement to do so.</p>
        <h1 className="terms__subHeading">Your Profile</h1>
        <p className="terms__description">Please note that your username, how long ago you created your account, log information, and any information included in the “About” field in your profile will be viewable by the public on your publicly accessible profile page. All profile creation shall be redirected to authorized third parties, in conjunction to be achieved vide Fatcat.</p>

        <h1 className="terms__subHeading">Your Choices</h1>
        <p className="terms__description"></p>You can use the Site without providing Personal Data, but you may not be able to use certain components of the Site.

        <h1 className="terms__subHeading"></h1>Exclusions
        <p className="terms__description"></p>This Privacy Policy shall not apply to any information posted to any public areas of the Site (such as comments or information posted in discussion forums), the information provided to Fatcat or any Fatcat personnel or members by email or through the Contact page on the Site, the information provided through social media platforms (collectively, “Unsolicited Information”). 

        <h1 className="terms__subHeading"></h1>Links to Other Websites
        <p className="terms__description">This Privacy Policy applies only to the Site, and not to third-party sites for which there may be links available in the Site. </p>

        <h1 className="terms__subHeading">Security</h1>
        <p className="terms__description">Fatcat takes consistent steps to protect Personal Data against malicious attacks over the internet. However, you should take special care while disclosing any Personal Data to Fatcat via the Internet.</p>

        <h1 className="terms__subHeading">Other Terms and Conditions</h1>
        <p className="terms__description">We may provide sub-policies within the user interface of certain applications, as and when applicable.</p>

        <h1 className="terms__subHeading">Changes to Fatcat’s Privacy Policy</h1>
        <p className="terms__description">Fatcat may update this Privacy Policy at any time without prior notice. If you continue using the Site after any changes, it shall indicate your agreement with the updated terms.</p>

        <h1 className="terms__subHeading">Contacting Fatcat</h1>
        <p className="terms__description">Please feel free to contact us if you have any questions about Fatcat’s Privacy Policy.</p>

        <p className="terms__description">You may contact us at sales@fatcat.net.in.</p>
        <br/><br/>

      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
