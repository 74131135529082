import React from "react";
import Navbar from "../StudentsPage/components/Navbar/Navbar";
import DigitalSolutionCards from "./components/DigitalSolutionCards/DigitalSolutionCards";
import DigitalSolutionStats from "./components/DigitalSolutionStats/DigitalSolutionStats";
import Footer from "./components/Footer/Footer";
import FutureDigital from "./components/FutureDigital/FutureDigital";
import Header from "./components/Header/Header";
import Solutions from "./components/Solutions/Solutions";
import Survey from "./components/Survery/Survey";
import Transformation from "./components/TransformationSection/Transformation";
import WOW from "wow.js";
import "./HomePage.styles.scss";

function HomePage() {
  require("../../animate.css");
  new WOW().init();
  return (
    <div className="homepage-container">
      <Navbar />
      <Header />

      {/* <DigitalSolutionCards />
      <Solutions />
      <div className="wow fadeInUp">
      <DigitalSolutionStats />
      </div>
      
      <FutureDigital />
      <Survey />
      <Transformation /> */}
      <Footer />
    </div>
  );
}

export default HomePage;
