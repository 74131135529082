import React from "react";
import Navbar from "../StudentsPage/components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import WOW from 'wow.js'
import "./TermsPage.styles.scss";

function HomePage() {
  require("../../animate.css");
  new WOW().init();
  return (
    <div className="homepage-container">
      <Navbar />
      <div className="terms__wrapper px-5">
        <h1 className="terms__heading">Terms of use</h1>
        <p className="terms__description">Welcome to Fatcat.com operated by Trilot Solutions Pvt. Ltd. (“Fatcat” or “we” or “us” or “our'' or "Site"). This Site provides information about our people, organization, partners, products, forums for discussions, and profiles of founders and institutions who have subscribed to and deployed the product(s) offered by Fatcat.</p>
        <p className="terms__description">UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST Fatcat ON AN INDIVIDUAL BASIS, AND (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF ON AN INDIVIDUAL BASIS.</p>
        <p className="terms__description">We reserve the right to change these Terms of Use at any time, and we will notify you via email if we change it. Such changes will become effective a minimum of 14 days after they are posted, except for changes regarding new features that will be effective immediately. If you continue using the Site after the change, it will be understood as your acceptance of the new Terms of Use.</p>
        <p className="terms__description">When using certain components of the Site, you will be subject to any additional terms as applicable to that particular component.</p>
        <p className="terms__description">All aspects in the Terms of Use are in agreement with the Information Technology Act, 2000 (“IT Act”) and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (“IT Rules”).</p>
        <h1 className="terms__subHeading">Access and Use of the Site</h1>
        <p className="terms__description"><b>Your Registration Obligations:</b> You have to register with Fatcat to access certain features of the Site. If you choose to register, you agree to provide and maintain accurate information. If you are under 15 years of age, you are not allowed to register to use the Site.</p>
        <p className="terms__description"><b>Account Security:</b> Fatcat will not be liable for any loss because of your not maintaining the confidentiality of your account details. </p>
        <p className="terms__description"><b>Modifications to Site:</b> Fatcat has the right to modify or discontinue, temporarily or permanently, the Site (or any part thereof) with or without notice. </p>
        <p className="terms__description"><b>General Practices Regarding Use and Storage:</b> You acknowledge that Fatcat may establish general practices and limits concerning the use of the Site. Fatcat reserves the right to terminate accounts that are inactive for an extended period. Fatcat reserves the right to change these general practices and limits at any time.</p>
        
        <h1 className="terms__subHeading">Conditions of Use</h1>
        <p className="terms__description"><b>User Conduct:</b> You are entirely responsible for all content that you upload to the Site. Fatcat has the right to investigate and take appropriate legal action against anyone who violates this provision. The following are examples of the kind of content that is prohibited by Fatcat.</p>

        <p className="terms__description">You agree to not use the Site to:</p>
        <ul className="terms__list">
          <li>E-mail or otherwise upload any content that (i) infringes any intellectual property; (ii) you do not have a right to upload; (iii) contains software viruses; (iv) creates a privacy or security risk; (v) constitutes unsolicited or unauthorized advertising; (vi) is unlawful or (vii) which inhibits any other person from using the Site, or which exposes Fatcat or its users to any harm;</li>
          <li>Disrupt the Site or disobey any policy;</li>
          <li>Violate any applicable law;</li>
          <li>Impersonate any person;</li>
          <li>Request for personal information from minors;</li>
          <li>Collect information of other users from the Site;</li>
          <li>Advertise goods or services for any business purpose that is authorized;</li>
          <li>Promote any criminal activity; or</li>
        </ul>

        <p className="terms__description"><b>Commercial Use:</b> You agree not to use the Site for any commercial purposes</p>

        <h1 className="terms__subHeading">Intellectual Property Rights</h1>

        <p className="terms__description">Site Content, Software, and Trademarks: Site Content is protected by proprietary rights and laws. Any use of the Site Content other than as specifically authorized herein is strictly prohibited. </p>

        <p className="terms__description">The Fatcat name and logos are trademarks of Trilot Solutions Private Limited. It cannot be used without our prior written permission.</p>

        <p className="terms__description">Third-Party Material: Fatcat will not be liable in any way for any content or materials of any third parties. Fatcat and its designees will have the right to remove any content that violates these Terms of Use.</p>

        <p className="terms__description"><b>User Content Transmitted Through the Site:</b> By uploading any User Content you hereby grant and will grant Fatcat an irrevocable license to use it. 
        Any information provided by you to Fatcat is non-confidential. Fatcat may preserve and disclose it if required.</p>

        <p className="terms__description">Copyright Complaints: If you believe that your work has been copied by Fatcat, written notification of claimed copyright infringement should be faxed or mailed to the registered address of Trilot Solutions Private Limited.</p>

        <h1 className="terms__subHeading">Third-Party Websites</h1>

        <p className="terms__description">The Site may provide links to third-party applications. Fatcat has no control over such sites does not endorse such sites.</p>

        <h1 className="terms__subHeading">Indemnity and Release</h1>

        <p className="terms__description">Fatcat will be held harmless from any losses arising out of or relating to your use of the Site.</p>

        <h1 className="terms__subHeading">Disclaimer of Warranties</h1>

        <p className="terms__description">
        Fatcat MAKES NO WARRANTY THAT (I) THE SITE WILL MEET YOUR REQUIREMENTS, (II) THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR (III) THE RESULTS OF THE SITE WILL BE RELIABLE.
        <br/> <br/>
        Limitation of Liability: Fatcat WILL NOT BE LIABLE FOR ANY LOSSES RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE SITE OR ANY RELATED INFORMATION; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY (INCLUDING USERS) ON THE SITE; OR (IV) ANY OTHER MATTER RELATING TO THE SITE. IN NO EVENT WILL Fatcat’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED INR 5,000 (INDIAN RUPEES FIVE THOUSAND).
        <br/> <br/>
        IF YOU ARE NOT HAPPY WITH THESE TERMS OF USE, YOUR SOLUTION IS TO STOP USE OF THE SITE.
        </p>

        <h1 className="terms__subHeading">Dispute Resolution By Binding Arbitration:</h1>


        <p className="terms__description">All disputes between you and Fatcat shall be resolved through arbitration, except that you may assert individual claims in small claims court. A neutral arbitrator will decide your rights. The Arbitration and Conciliation Act ,1996 governs this Arbitration Agreement.</p>

        <h1 className="terms__subHeading">Prohibition of Direct and Representative Claims</h1>

        <p className="terms__description">Claims will be arbitrated on an individual case basis, and not combined. </p> 

        <p className="terms__description">Most user concerns can be resolved quickly by email. A party who intends to seek arbitration must send a written Notice of Dispute (“Notice”) to the registered address. If Fatcat and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or Fatcat may commence an arbitration proceeding. </p>

        <h1 className="terms__subHeading">Arbitration Procedures</h1>

        <p className="terms__description">Arbitration will be conducted by a neutral arbitrator following the Arbitration and Conciliation Act 1996 and any rules and procedures thereof (collectively, the “Arbitration Act”), as modified by this Arbitration Agreement.</p>

        <h1 className="terms__subHeading">Costs of Arbitration</h1>

        <p className="terms__description">Fees will be governed by the Arbitration Act.</p>

        <h1 className="terms__subHeading">Confidentiality</h1>

        <p className="terms__description">All aspects of the arbitration proceeding will be strictly confidential for the benefit of all parties.</p>

        <h1 className="terms__subHeading">Severability</h1>

        <p className="terms__description">A court or the arbitrator can term this Arbitration Agreement invalid. Then this Arbitration Agreement shall become invalid. The rest of the Terms of Use will continue to apply.</p>

        <h1 className="terms__subHeading">Future Changes to Arbitration Agreement</h1>

        <p className="terms__description">Fatcat can make any future change to this Arbitration Agreement with 30 days notice period. </p>

        <h1 className="terms__subHeading">Termination</h1>
        <p className="terms__description">You agree that Fatcat, in its sole discretion, may suspend or terminate your account. </p>

        <h1 className="terms__subHeading">User Disputes</h1>

        <p className="terms__description">Only you are accountable for your interactions with other users in the Site.</p>

        <h1 className="terms__subHeading">General</h1>

        <p className="terms__description">These Terms of Use will be governed by the laws of India without regard to its conflict of law provisions. Any claim arising out of these Terms of Use must be filed within one (1) year.</p>

        <h1 className="terms__subHeading">Your Privacy</h1>

        <p className="terms__description">Please see our Privacy Policy.</p>

        <h1 className="terms__subHeading">Contact Us</h1>

        <p className="terms__description">Please contact us at sales@fatcat.net.in.</p> 
        <br/><br/>

      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
